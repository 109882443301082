<template>
  <div>
    <video-player :options="videoOptions" class="video"></video-player>
    <chat />
  </div>
</template>

<style scoped="scss">
  .video {
    max-width: 100%;
    margin: auto;
  }
</style>

<script>
// @ is an alias to /src
import VideoPlayer from '@/components/video.vue';
import Chat from '@/components/chat.vue';

export default {
  name: 'Home',
  components: {
    VideoPlayer,
    Chat
  },
  data() {
    return {
      videoOptions: {
        width: '1080px',
        autoplay: true,
        controls: true,
        sources: [
          {
            src: 'https://d7b30omg1li4r.cloudfront.net/main_480p.m3u8'
          }
        ]
      }
    };
  }
};
</script>
