<template>
    <div>
      <div ref="videocontainer">
        <video
          playsinline
          autoplay
          preload="auto"
          ref="video"
          id="video"
          class="shaka-player">
        </video>
      </div>
      <div>
        <div>버퍼 : {{logs.buffer}}</div>
        <div>스테이터스 : {{logs.stats}}</div>
      </div>
    </div>
  </div>
</template>
<style scoped="scss">
  .shaka-player {
    max-width: 100%;
    margin: auto;
  }
</style>
<script>
// import shaka from 'shaka-player.ui';
import shaka from 'shaka-player/dist/shaka-player.ui';
import 'shaka-player/dist/controls.css';

export default {
  name: 'ShakaPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null,
      video: null,
      controls: null,
      showup: false,
      shakaUiLoaded: false,
      logs: {
        buffer: {},
        stats: {}
      }
    };
  },
  created() {
    console.log('created');
  },
  mounted() {
    console.log('mounted');
    this.video = this.$refs.video;
    console.log(shaka.Player);
    document.addEventListener('shaka-ui-loaded', this.shakaUiLoadedHandler);
    document.addEventListener('shaka-ui-load-failed', this.initFailed);
    this.init();
  },
  destroyed() {
    console.log('destroyed');
    document.removeEventListener('shaka-ui-loaded', this.shakaUiLoadedHandler);
    document.removeEventListener('shaka-ui-load-failed', this.initFailed);
  },
  methods: {
    shakaUiLoadedHandler() {
      console.log('shaka-ui-loaded');
      this.shakaUiLoaded = true;
      this.init();
    },
    initFailed() {
      console.log('shaka-ui-load-failed');
    },
    init() {
      console.log('init');
      this.container = this.$refs.videocontainer;
      this.player = new shaka.Player(this.video);
      this.ui = new shaka.ui.Overlay(this.player, this.container, this.video);
      window.player = this.player;
      window.ui = this.video.ui;
      const config = {
        overflowMenuButtons: ['cast', 'quality']
      };
      this.video.ui.configure(config);
      this.player.configure({
        streaming: {
          inaccurateManifestTolerance: 0,
          rebufferingGoal: 0.01,
        },
        drm: {
          servers: {
            'com.microsoft.playready': 'https://license.pallycon.com/ri/licenseManager.do',
            'com.widevine.alpha': 'https://license.pallycon.com/ri/licenseManager.do',
          }
        },
      });
      this.player.configure('manifest.dash.ignoreMinBufferTime', true); // 버퍼타임 재정의
      this.player.getNetworkingEngine().registerRequestFilter(function (type, request) {
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
          request.headers['pallycon-customdata-v2'] = 'eyJkYXRhIjoicGdrMDVPbC9hZC9FQVVzU1BNVVdrN1RCaXRnZ1hCZVVlTXh0cUgxSlhadTBHRlIrN2JsbTAvVEVTenZQQVMzZyIsImRybV90eXBlIjoiV2lkZXZpbmUiLCJzaXRlX2lkIjoiU1IyTCJ9';
        }
      });
      this.loadurl();
    },
    loadurl() {
      return window.player.load('https://d2hbcz3jrdwxr.cloudfront.net/out/v1/f6f4e3d7358a4dd59089746af3ae0fc7/index.mpd');
    }
  },
};
</script>
