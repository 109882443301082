<template>
  <div>
    <video-player :options="videoOptions" class="video"></video-player>
    <chat />
  </div>
</template>

<style scoped="scss">
  .video {
    max-width: 100%;
    margin: auto;
  }
</style>

<script>
// @ is an alias to /src
import VideoPlayer from '@/components/hls.vue';
import Chat from '@/components/chat.vue';

export default {
  name: 'Home',
  components: {
    VideoPlayer,
    Chat
  },
  data() {
    return {
      videoOptions: {
        width: '1080px',
        autoplay: true,
        controls: true,
        sources: [
          {
            src: 'https://d2hbcz3jrdwxr.cloudfront.net/out/v1/117fdf36ddb3451e957d6c46873efd20/index.m3u8',
            keySystems: {
              'com.apple.fps.1_0': {
                certificateUri: 'https://license.pallycon.com/ri/fpsKeyManager.do?siteId=SR2L',
                licenseUri: 'https://license.pallycon.com/ri/licenseManager.do',
                licenseHeaders: {
                  'pallycon-customdata-v2': 'eyJkYXRhIjoicGdrMDVPbC9hZC9FQVVzU1BNVVdrN1RCaXRnZ1hCZVVlTXh0cUgxSlhadTBHRlIrN2JsbTAvVEVTenZQQVMzZyIsImRybV90eXBlIjoiV2lkZXZpbmUiLCJzaXRlX2lkIjoiU1IyTCJ9'
                }
              }
            }
          }
        ]
      }
    };
  }
};
</script>
