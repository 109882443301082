<template>
    <div>
      <div ref="videocontainer">
        <video
          playsinline
          autoplay
          preload="auto"
          ref="video"
          id="video"
          class="shaka-player">
        </video>
      </div>
      <div>
        <div>버퍼 : {{logs.buffer}}</div>
        <div>스테이터스 : {{logs.stats}}</div>
      </div>
    </div>
  </div>
</template>
<style scoped="scss">
  .shaka-player {
    max-width: 100%;
    margin: auto;
  }
</style>
<script>
// import shaka from 'shaka-player.ui';
import shaka from 'shaka-player/dist/shaka-player.ui';
import 'shaka-player/dist/controls.css';
import axios from 'axios';
import arrayBufferToString from 'arraybuffer-to-string';

export default {
  name: 'ShakaPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null,
      video: null,
      controls: null,
      showup: false,
      shakaUiLoaded: false,
      logs: {
        buffer: {},
        stats: {}
      }
    };
  },
  created() {
    console.log('created');
  },
  mounted() {
    console.log('mounted');
    this.video = this.$refs.video;
    document.addEventListener('shaka-ui-loaded', this.shakaUiLoadedHandler);
    document.addEventListener('shaka-ui-load-failed', this.initFailed);
    this.init();
  },
  destroyed() {
    console.log('destroyed');
    document.removeEventListener('shaka-ui-loaded', this.shakaUiLoadedHandler);
    document.removeEventListener('shaka-ui-load-failed', this.initFailed);
  },
  methods: {
    shakaUiLoadedHandler() {
      console.log('shaka-ui-loaded');
      this.shakaUiLoaded = true;
      this.init();
    },
    initFailed() {
      console.log('shaka-ui-load-failed');
    },
    init() {
      console.log('init');
      this.container = this.$refs.videocontainer;
      this.player = new shaka.Player(this.video);
      this.ui = new shaka.ui.Overlay(this.player, this.container, this.video);
      window.player = this.player;
      window.ui = this.video.ui;
      // const fairplayCert = this.getFairplayCert();
      this.getFairplayCert()
        .then((fairplayCert) => {
          const config = {
            overflowMenuButtons: ['cast', 'quality']
          };
          this.video.ui.configure(config);
          this.player.configure({
            drm: {
              servers: {
                'com.apple.fps.1_0': 'https://license.pallycon.com/ri/licenseManager.do'
              },
              advanced: {
                'com.apple.fps.1_0': {
                  serverCertificate: new Uint8Array(fairplayCert)
                }
              },

            },
          });
          // this.player.configure('manifest.dash.ignoreMinBufferTime', true); // 버퍼타임 재정의
          this.player.configure('drm.initDataTransform', (initData, initDataType) => {
            console.log(initDataType);
            if (initDataType !== 'skd') return initData;

            // 'initData' is a buffer containing an 'skd://' URL as a UTF-8 string.
            const skdUri = shaka.util.StringUtils.fromBytesAutoDetect(initData);
            const contentId = skdUri.substring(skdUri.indexOf('skd://') + 6);
            const cert = this.player.drmInfo().serverCertificate;
            return shaka.util.FairPlayUtils.initDataTransform(initData, contentId, cert);
          });
          this.player.getNetworkingEngine().registerRequestFilter((type, request) => {
            console.log(type, shaka.net.NetworkingEngine.RequestType.LICENSE);
            if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
              return;
            }

            const originalPayload = new Uint8Array(request.body);
            const base64Payload = shaka.util.Uint8ArrayUtils.toStandardBase64(originalPayload);
            const params = `spc=${base64Payload}`;
            request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
            request.body = shaka.util.StringUtils.toUTF8(encodeURIComponent(params));
            request.headers['pallycon-customdata-v2'] = 'eyJkYXRhIjoicGdrMDVPbC9hZC9FQVVzU1BNVVdrN1RCaXRnZ1hCZVVlTXh0cUgxSlhadTBHRlIrN2JsbTAvVEVTenZQQVMzZyIsImRybV90eXBlIjoiV2lkZXZpbmUiLCJzaXRlX2lkIjoiU1IyTCJ9';
          });

          this.player.getNetworkingEngine().registerResponseFilter((type, response) => {
            if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
              return;
            }

            let responseText = shaka.util.StringUtils.fromUTF8(response.data);
            // Trim whitespace.
            responseText = responseText.trim();

            // Look for <ckc> wrapper and remove it.
            if (responseText.substr(0, 5) === '<ckc>' && responseText.substr(-6) === '</ckc>') {
              responseText = responseText.slice(5, -6);
            }

            // Decode the base64-encoded data into the format the browser expects.
            response.data = shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
          });
          this.player.getNetworkingEngine().registerResponseFilter((type, response) => {
            // Alias some utilities provided by the library.
            if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
              console.log(response.data.byteLength);
              const responseText = arrayBufferToString(response.data);
              console.log(`response : ${responseText}`);
              if (responseText.indexOf('errorCode') > 0) {
                const { errorCode } = JSON.parse(responseText).errorCode;
                if (errorCode !== '8002') {
                  alert(`PallyCon Error : ${JSON.parse(responseText).message} (${JSON.parse(responseText).errorCode})`);
                } else {
                  const { message } = JSON.parse(responseText).message;
                  alert(`Error : ${JSON.parse(message).MESSAGE} (${JSON.parse(message).ERROR}`);
                }
              }
            }
          });
          this.loadurl();
        });
    },
    loadurl() {
      return window.player.load('https://d2hbcz3jrdwxr.cloudfront.net/out/v1/117fdf36ddb3451e957d6c46873efd20/index.m3u8');
    },
    getFairplayCert() {
      const fpsCertUrl = 'https://license.pallycon.com/ri/fpsKeyManager.do?siteId=SR2L';
      return axios.get(fpsCertUrl)
        .then((responseData) => responseData.data);
    },
    base64DecodeUint8Array(input) {
      const raw = window.atob(input);
      const rawLength = raw.length;
      const array = new Uint8Array(new ArrayBuffer(rawLength));

      for (let i = 0; i < rawLength; i += 1) {
        array[i] = raw.charCodeAt(i);
      }

      return array;
    }
  },
};
</script>
