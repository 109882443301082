<template>
  <div>
    <video-player :options="videoOptions" class="video"></video-player>
    <chat />
  </div>
</template>

<style scoped="scss">
  .video {
    max-width: 100%;
    margin: auto;
  }
</style>

<script>
// @ is an alias to /src
import VideoPlayer from '@/components/shaka.vue';
import Chat from '@/components/chat.vue';

export default {
  name: 'Home',
  components: {
    VideoPlayer,
    Chat
  },
  data() {
    return {
      videoOptions: {
        width: '1080px',
        autoplay: true,
        controls: true,
        liveui: true,
        sources: [
          {
            src: 'https://d2hbcz3jrdwxr.cloudfront.net/out/v1/f6f4e3d7358a4dd59089746af3ae0fc7/index.mpd',
            type: 'application/dash+xml',
            keySystemOptions: [
              {
                name: 'com.widevine.alpha',
                options: {
                  serverURL: 'https://license.pallycon.com/ri/licenseManager.do',
                  httpRequestHeaders: {
                    'pallycon-customdata-v2': 'eyJkYXRhIjoicGdrMDVPbC9hZC9FQVVzU1BNVVdrN1RCaXRnZ1hCZVVlTXh0cUgxSlhadTBHRlIrN2JsbTAvVEVTenZQQVMzZyIsImRybV90eXBlIjoiV2lkZXZpbmUiLCJzaXRlX2lkIjoiU1IyTCJ9',
                  }
                }
              },
              {
                name: 'com.microsoft.playready',
                options: {
                  serverURL: 'https://license.pallycon.com/ri/licenseManager.do',
                  httpRequestHeaders: {
                    'pallycon-customdata-v2': 'eyJkYXRhIjoicGdrMDVPbC9hZC9FQVVzU1BNVVdrN1RCaXRnZ1hCZVVlTXh0cUgxSlhadTBHRlIrN2JsbTAvVEVTenZQQVMzZyIsImRybV90eXBlIjoiV2lkZXZpbmUiLCJzaXRlX2lkIjoiU1IyTCJ9',
                  }
                }
              }
            ]
          }
        ]
      }
    };
  }
};
</script>
